import React from 'react';
import s from './Header.module.scss';
import {Logo} from '../../Logo/Logo';
import {ButtonLink} from '../../buttons/ButtonLink/ButtonLink';

export const Header: React.FC = () => {

	// * Render
	return (
		<header className={s.container}>
			<a href="https://denominator.one/">
				<Logo/>
			</a>

			<div className={s.links}>
				<ButtonLink
					title="About Denominator"
					url="https://www.denominator.one/about/"
					variant="tertiary"
				/>

				<ButtonLink
					title="Contact Us"
					url="https://www.denominator.one/contact?source=denominator-country-header"
					variant="tertiary"
				/>
			</div>
		</header>
	);
};
